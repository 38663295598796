<template>
  <div class="breadcrumbs">
    <router-link
        v-if="isAuthenticated"
      :to="userHomeUrl"
      class="has-text-white hidden-print"
      style="margin-right: 5px;"
    >
      <font-awesome-layers fixed-width>
        <font-awesome-icon
          icon="fa-regular fa-circle"
          size="lg"
        />
        <font-awesome-icon
          icon="fa-solid fa-home"
          transform="shrink-8"
        />
      </font-awesome-layers>
    </router-link>
    <a
      class="has-text-white hidden-print is-clickable"
      style="margin-right: 5px;"
      @click="$router.go(-1)"
    >
      <font-awesome-layers fixed-width>
        <font-awesome-icon
          icon="fa-regular fa-circle"
          size="lg"
        />
        <font-awesome-icon
          icon="fa-solid fa-arrow-left"
          transform="shrink-6"
        />
      </font-awesome-layers>
    </a>
  </div>
</template>

<script>
import {FontAwesomeLayers} from '@fortawesome/vue-fontawesome';
import User from '../models/User';
import {defineComponent} from "vue";
import {mapState} from "pinia";
import {useAuthStore} from "@/stores/auth.js";

export default defineComponent({
  name: 'Breadcrumb',
  components: {
    FontAwesomeLayers,
  },
  computed: {
    userHomeUrl() {
      if (User.me().group_id === 3 && this.$route.params.flatId) {
        return `/tenant/${this.$route.params.flatId}/dashboard`;
      }
      return User.me().homeUrl;
    },
      ...mapState(useAuthStore, ['isAuthenticated'])
  },
});
</script>

<style scoped>
a {
    text-decoration: none;
}

.breadcrumbs {
    display: inline;
}

span {
    font-size: 12pt;
}

.back-button {
    cursor: pointer;
}
</style>
