<template>
  <div
    id="app"
    class="container"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>

</style>
