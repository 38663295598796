import {Model, useRepo} from 'pinia-orm';
import Flat from './Flat';
import User from './User';
import Contract from './Contract';
import Meter from './Meter';

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export default class Property extends Model {
  static entity = 'properties';

  static fields() {
    return {
      id: Property.attr(''),
      uuid: Property.attr(''),
      propertynumber: Property.string(''),
      street: Property.string(''),
      housenumber: Property.string(''),
      postalcode: Property.string(''),
      city: Property.string(''),
        lat: Property.number(null),
        lng: Property.number(null),
        squareMeters: Property.number(null),
        objectnumber: Property.string(null),
        debtornumber: Property.string(null),
      period_month: Property.number(12),
      period_day: Property.number(31),
        image: Property.string(null),
        image_thumb: Property.string(null),
        image_link: Property.string(null),
        note: Property.attr(null),
      basic: Property.boolean(false),
      flats: Property.hasMany(Flat, 'property_id'),
      vacancy: Property.hasMany(Flat, 'property_id'),
      mainMeters: Property.morphMany(Meter, 'meters_id', 'meters_type'),
        responsibleEmployee: Property.attr(null),
        responsibleLandlord: Property.attr(null),
      propertyManagers: Property.morphToMany(
        User,
        Contract,
        'user_id',
        'manageables_id',
        'manageables_type',
      ),
        contracts: Property.morphMany(Contract, 'manageables_id', 'manageables_type'),
        activeContracts: Property.morphMany(Contract, 'manageables_id', 'manageables_type'),
        latestContract: Property.morphOne(
            Contract,
            'manageables_id',
            'manageables_type',
        ),
        settings: Property.attr({}),
        meta: Property.attr(null),
        consumption: Property.attr(null),
        mainmeters_count: Property.number(null),
        submeters_count: Property.number(null),
    };
  }

    static repo() {
        return useRepo(Property);
    }
}
